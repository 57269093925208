@import "~@/styles/variables/variables.scss";





























































































































































































































































































































































@import '../../run-manage/runLayout.scss';
.directive-detail-main {
	flex-direction: column;
	padding: 0 20px;
	.directive-detail-table-show {
		flex: 1;
		display: flex;
		flex-direction: column;
		height: 0;
		.table-header {
			font-size: 17px;
			font-weight: 600;
			color: #333333;
			padding-bottom: 20px;
		}
		.table-box {
			flex: 1;
			height: 0;
			display: flex;
			flex-direction: column;
			.serach {
				display: flex;
				flex-wrap: wrap;
				.serach-ops {
					::v-deep .el-button {
						border-radius: 8px;
					}
				}
				& > div {
					margin-right: 12px;
					margin-bottom: 8px;
				}
				.export-ops {
					margin-left: auto;
					margin-right: 0;
				}
			}
			::v-deep .gc-table {
				flex: 1;
				height: 0;
				margin-top: 8px;
			}
		}
	}
}
::v-deep .desc {
	.el-textarea.is-disabled .el-textarea__inner {
		border: 1px solid #d9d9d9;
		background: #ffffff !important;
		color: #4e4e4e;
	}
}
